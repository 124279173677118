import React, { createContext, useContext, useReducer, useRef } from "react"
import axios from "axios"
import { connect, LocalVideoTrack } from "twilio-video"
import { client } from "../apollo/client"
import { ApolloProvider } from "@apollo/react-hooks"

const TWILIO_TOKEN_URL = `https://inchworm-rail-1685.twil.io/create-room-token`
const DEFAULT_STATE = {
  identity: false,
  roomName: false,
  token: false,
  room: false,
  isOner: false,
  roomPin: "",
}

const reducer = (state, action) => {
  switch (action.type) {
    case "join":
      return {
        ...state,
        token: action.token,
        identity: action.identity,
        roomName: action.roomName,
        roomPin: action.roomPin,
      }

    case "set-active-room":
      return { ...state, room: action.room }

    case "disconnect":
      state.room && state.room.disconnect()
      return DEFAULT_STATE

    default:
      return DEFAULT_STATE
  }
}

const TwilioVideoContext = createContext()

const TwilioVideoProvider = ({ children }) => (
  <TwilioVideoContext.Provider value={useReducer(reducer, DEFAULT_STATE)}>
    {children}
  </TwilioVideoContext.Provider>
)

export const wrapRootElement = ({ element }) => (
  <ApolloProvider client={client}>
    <TwilioVideoProvider>{element}</TwilioVideoProvider>
  </ApolloProvider>
)

const useTwilioVideo = () => {
  const [state, dispatch] = useContext(TwilioVideoContext)
  const videoRef = useRef()
  const screenShareRef = useRef()
  const getRoomToken = async ({ identity, roomName, roomPin }) => {
    const res = await axios.post(TWILIO_TOKEN_URL, {
      identity,
      room: roomName,
    })
    dispatch({ type: "join", token: res.data, identity, roomName, roomPin })
  }

  const handleRemoteParticipant = (container, con2, participant) => {
    console.log("called, checking to see if adding a screen share counts")
    const id = participant.sid

    const el = document.createElement("div")
    el.id = id
    el.className = "remote-participant"

    const name = document.createElement("h4")
    name.innerText = participant.identity
    el.appendChild(name)
    container.appendChild(el)

    const addTrack = track => {
      console.log(track.name)
      if (track.name === "content") {
        screenShareRef.current.innerHTML = ""
        const content = track.attach()
        screenShareRef.current.appendChild(content)
      } else {
        const participantDiv = document.getElementById(id)
        const media = track.attach()
        participantDiv.appendChild(media)
      }
    }

    participant.tracks.forEach(publication => {
      console.log("called in remote part", participant)
      if (publication.isSubscribed) {
        addTrack(publication.track)
      }
    })

    participant.on("trackSubscribed", addTrack)

    participant.on("trackUnsubscribed", track => {
      track.detach().forEach(el => el.remove())
      const container = document.getElementById(id)
      if (container) container.remove()
    })
  }
  const connectToRoom = async () => {
    if (!state.token) return
    const room = await connect(state.token, {
      name: state.roomName,
      audio: true,
      video: { width: 640 },
      loglevel: "info",
    }).catch(error => {
      console.log(`Unable to join room: ${error}`)
    })
    //const localTrack = [...room.localParticipant.videoTracks.value()][0].track
    console.log(room.localParticipant.videoTracks)

    const localTrack = [...room.localParticipant.videoTracks.values()][0].track
    console.log(localTrack, videoRef.current.hasChildNodes())
    if (!videoRef.current.hasChildNodes()) {
      const localEl = localTrack.attach()

      videoRef.current.appendChild(localEl)
    }

    const handleParticipant = participant => {
      handleRemoteParticipant(
        videoRef.current,
        screenShareRef.current,
        participant
      )
    }

    room.participants.forEach(handleParticipant)
    room.on("participantConnected", handleParticipant)
    // room.on('trackAdded', (track, participant) => {

    // });

    dispatch({ type: "set-active-room", room })
  }
  const startVideo = () => connectToRoom()
  const leaveRoom = () => dispatch({ type: "disconnect" })
  const shareScreen = async () => {
    const stream = await navigator.mediaDevices.getDisplayMedia()
    const screenTrack = new LocalVideoTrack(stream.getTracks()[0], {
      name: "content",
    })

    state.room.localParticipant.publishTrack(screenTrack)
    screenShareRef.current.innerHTML = ""
    const localElScreenShare = screenTrack.attach()
    screenShareRef.current.appendChild(localElScreenShare)
  }

  return {
    state,
    getRoomToken,
    startVideo,
    videoRef,
    screenShareRef,
    leaveRoom,
    shareScreen,
  }
}

export default useTwilioVideo
